<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <div class="global-header-image">
        <img
          v-if="repair.vehicle.gallery.length"
          :src="repair.vehicle.gallery[0]"
          alt=""
        />
        <img v-else class="default" src="/img/add-image.svg" />
      </div>
      <div class="global-header-text">
        <h3>{{ repair.code }} - {{ $t("COMMON.INSPECTION") }}</h3>
        <div class="buttons">
          <button class="edit" @click="showInspection">
            <span> {{ $t("INSPECTIONS.SHOW_INSPECTION") }} </span>
          </button>
        </div>
      </div>
    </div>
    <inspection-view-global
      class-name=""
      :inspection="repair.inspection"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import InspectionViewGlobal from "../../InspectionManagement/partials/InspectionViewGlobal.vue";

export default {
  name: "repair-view-global",

  components: { InspectionViewGlobal },

  props: ["repair"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    showInspection() {
      this.$router.push(this.$objectViewRoute(this.repair.inspection));
    },
  },

  mounted() {},

  watch: {},
};
</script>
