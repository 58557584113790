<template>
  <div class="container-fluid repairs-global">
    <div class="page-title">
      <i class="fa-regular fa-wrench"></i>
      <h1>{{ $t("COMMON.REPAIRS") }}</h1>
    </div>

    <div class="list-table">
      <repair-list-table
        :key="renderKey * 100"
        @onAddRepair="openRepairCreateModal"
        @onDeleteRepair="openDeleteModal"
        @onEditRepair="openRepairEditModal"
        @onViewRepair="openRepairViewModal"
        show-in-progresses
        show-tabs
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewRepairModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewRepairModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REPAIR'"
          @onCloseModal="closeRepairModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("REPAIRS.VIEW_REPAIR") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openRepair"
                :objectType="'repairs'"
                :objectId="openRepair.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
                v-if="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openRepairEditModal(openRepair)"
                  v-if="canEditRepair(openRepair)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteRepair(openRepair)"
                  v-if="canDeleteRepair(openRepair)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeRepairModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-repair-component
              v-if="openRepair"
              :repairId="openRepair.id"
              @repairLoaded="
                (repair) => {
                  openRepair = repair;
                  openRepairLoaded = true;
                }
              "
              @onViewRepair="openRepairViewModal"
              @onCancelRepair="cancelRepair"
              @onEndRepair="showCompleteRepairModal = true"
              @onCloseRepairModal="closeRepairModal"
              @onEditRepair="openRepairEditModal"
              @onTakeOverRepair="takeOverRepair"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditRepairModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditRepairModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REPAIR'"
          @onCloseModal="closeRepairModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <!-- <button class="back-button" @click="closeRepairModal">
                <i class="fal fa-arrow-left"></i>
              </button> -->
              <h1>
                {{ $t("REPAIRS.EDIT_REPAIR") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="openRepairViewModal(openRepair)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-repair-component
              v-if="openRepair"
              :repairId="openRepair.id"
              :loading="loading"
              @onCancelRepair="cancelRepair"
              @onViewRepair="openRepairViewModal"
              @onCloseRepairModal="closeRepairModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddRepairModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddRepairModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REPAIR'"
          @onCloseModal="closeRepairModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>{{ $t("REPAIRS.ADD_REPAIR") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="btn expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeRepairModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-repair-component
              @onViewRepair="openRepairViewModal"
              @onEditRepair="openRepairEditModal"
              @onCloseRepairModal="closeRepairModal"
              :vehicle="vehicleId"
            />
          </div>
        </div>
      </div>
    </transition>

    <inspection-confirmation-modal
      :confirm-action="() => deleteRepair(openRepair)"
      :confirm-button-text="$t('COMMON.YES_DELETE')"
      :loading="loading"
      :message="$t('REPAIRS.DELETE_THIS_REPAIR')"
      :open.sync="showDeleteRepairModal"
      modal-classes="modal-secondary cancel"
    />

    <inspection-confirmation-modal
      :confirm-action="
        () => {
          showCommentAndSignModal = true;
        }
      "
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="loading"
      :message="$t('REPAIRS.END_THIS_REPAIR')"
      :open.sync="showCompleteRepairModal"
      modal-classes="modal-secondary cancel"
    />

    <repair-view-repair-comment-and-sign
      v-if="showCommentAndSignModal"
      @onCloseModal="
        () => {
          showCommentAndSignModal = false;
          showCompleteRepairModal = false;
        }
      "
      @onSignAndSend="endRepair"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import RepairListTable from "./partials/RepairListTable.vue";
import AddRepairComponent from "./components/AddRepairComponent.vue";
import EditRepairComponent from "./components/EditRepairComponent.vue";
import ViewRepairComponent from "./components/ViewRepairComponent.vue";
// import repairsService from "@/store/services/repairs-service";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import RepairViewRepairCommentAndSign from "./partials/RepairViewRepairCommentAndSign.vue";

export default {
  layout: "DashboardLayout",

  components: {
    NotificationSubscription,
    RepairListTable,
    AddRepairComponent,
    EditRepairComponent,
    ViewRepairComponent,
    InspectionConfirmationModal,
    RepairViewRepairCommentAndSign,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, repairPermissionsMixin],

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  data() {
    const repairId = this.$route.query.id;
    const action = this.$route.query.action;
    const vehicleId = this.$route.query.vehicle_id;
    let isViewRepairModalOpened = false;
    let isEditRepairModalOpened = false;
    let isAddRepairModalOpened = false;
    let openRepair = null;
    if (repairId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewRepairModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditRepairModalOpened = true;
      }
      openRepair = { id: repairId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddRepairModalOpened = true;
    }
    return {
      selectedRepairStatus: null,
      selectedEstimationCreator: null,
      openRepairLoaded: false,
      isViewRepairModalOpened: isViewRepairModalOpened,
      isEditRepairModalOpened: isEditRepairModalOpened,
      isAddRepairModalOpened: isAddRepairModalOpened,
      openRepair,
      renderKey: 1,
      loading: false,
      showDeleteRepairModal: false,
      showCommentAndSignModal: false,
      showCompleteRepairModal: false,
      vehicleId,
    };
  },

  created() {},

  methods: {
    closeRepairModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      if (
        this.isAddRepairModalOpened ||
        this.isViewRepairModalOpened ||
        this.isEditRepairModalOpened
      ) {
        this.renderKey++;
      }
      this.isAddRepairModalOpened = false;
      this.isViewRepairModalOpened = false;
      this.isEditRepairModalOpened = false;
      this.openRepairLoaded = false;
      this.openRepair = null;
      this.vehicleId = null;

      if (
        this.$route.name !== "List Repairs" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Repairs",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    openRepairCreateModal() {
      this.closeRepairModal();
      this.isAddRepairModalOpened = true;
      this.openRepairLoaded = false;

      this.$router.replace({
        name: "List Repairs",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    openRepairViewModal(repair, reRender = false) {
      if (this.canEditRepair(repair)) {
        this.openRepairEditModal(repair);
      }

      this.closeRepairModal();
      this.openRepair = repair;
      this.isViewRepairModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Repairs",
        query: { id: this.openRepair.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openRepairEditModal(repair) {
      if (!this.canEditRepair(repair)) {
        this.openRepairViewModal(repair);
      }

      this.closeRepairModal();
      this.openRepair = repair;
      this.isEditRepairModalOpened = true;

      this.$router.replace({
        name: "List Repairs",
        query: { id: this.openRepair.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    async takeOverRepair(repair, data) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/takeOver", {
          repairId: repair.id,
          data,
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_TOOK_OVER"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async cancelRepair(repair) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/cancel", repair.id);
        // this.renderKey++;
        this.openRepairViewModal(repair, true);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async endRepair(comment, signature) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/complete", {
          repairId: this.openRepair.id,
          data: {
            comment,
            signature,
          },
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_ENDED"),
        });
        this.showCompleteRepairModal = false;
        this.showCommentAndSignModal = false;
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    openDeleteModal(repair) {
      this.openRepair = repair;
      this.showDeleteRepairModal = true;
    },

    async deleteRepair(repair) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/destroy", repair.id);
        this.renderKey++;
        this.closeRepairModal();
        this.showDeleteRepairModal = false;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_DELETED"),
        });
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    showDeleteRepairModal(value) {
      if (!value) {
        // this.showDeleteRepairModal = false;
        // this.openRepair = null;
      }
    },
  },
};
</script>
