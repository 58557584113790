<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <div class="global-header-image">
        <img
          v-if="repair.vehicle.gallery.length"
          :src="repair.vehicle.gallery[0]"
          alt=""
        />
        <img v-else class="default" src="/img/add-image.svg" />
      </div>
      <div class="global-header-text">
        <h3>{{ repair.code }} - {{ $t("COMMON.ESTIMATION") }}</h3>
        <div class="buttons">
          <button class="edit" @click="showEstimation" v-if="canShowEstimation">
            <span> {{ $t("INSPECTIONS.SHOW_ESTIMATION") }} </span>
          </button>
        </div>
      </div>
    </div>
    <estimate-view-global
      class-name=""
      :estimate="repair.estimation"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import EstimateViewGlobal from "../../EstimateManagement/partials/EstimateViewGlobal.vue";

export default {
  name: "repair-view-estimate",

  components: { EstimateViewGlobal },

  props: ["repair"],

  data() {
    return {};
  },

  computed: {
    canShowEstimation: function () {
      return (
        !!this.repair.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_ESTIMATIONS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
  },

  created() {},

  methods: {
    showEstimation() {
      this.$router.push(this.$objectViewRoute(this.repair.estimation));
    },
  },

  mounted() {},

  watch: {},
};
</script>
